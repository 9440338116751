import {
  UseFormRegister,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
  UseFormTrigger,
  FieldValues,
  Control,
} from 'react-hook-form';

export enum TabsTypes {
  AVAILABLE_TAB = 0,
  CONNECTED_TAB = 1,
}

export enum InputsSchemas {
  GITLAB = 'idscience-gitlab',
  JIRA = 'idscience-jira',
  GITHUB = 'idscience-github',
  OKTA = 'idscience-okta',
  AZURE_AD = 'idscience-azuread',
  BITBUCKET = 'idscience-bitbucket',
  JENKINS = 'idscience-jenkins',
  CIRCLE_CI = 'idscience-circleci',
  COMPASS = 'idscience-compass',
  DOCKERHUB = 'idscience-dockerhub',
  AZURE_DEVOPS = 'idscience-azuredevops',
  GOOGLE_DIR = 'idscience-googledir',
  SLACK = 'idscience-slack',
  TEAMS = 'idscience-teams',
  FLOCK = 'idscience-flock',
  EMAIL = 'email',
  IDSCIENCE_EMAIL = 'idscience-email',
  SPLUNK = 'idscience-splunk',
  SPLUNK_INBOUND = 'idscience-splunk-log',
  SNYK = 'idscience-snyk',
  BLACKDUCK = 'idscience-blackduck',
  ECR = 'idscience-ecr',
  JFROG = 'idscience-jfrog',
  WEBHOOK = 'idscience-webhook',
  FORGE_BITBUCKET = 'idscience-forge_bitbucket',
  SONARQUBE = 'idscience-sonarqube',
  PRISMA = 'idscience-prisma',
  HARNESS = 'idscience-harness',
  INVICTI = 'idscience-invicti',
  BAMBOO = 'idscience-bamboo',
}

export interface InputSchemaProps {
  register: UseFormRegister<any>;
  errors: FieldErrors;
  setValue: UseFormSetValue<any>;
  apiToken?: string;
  clientId?: string;
  url?: string;
  watch: UseFormWatch<any>;
  params?: Record<string, any>;
  ref?: any;
  trigger: UseFormTrigger<FieldValues>;
  activeIntegration?: string;
  shortHash?: string;
  type: string;
  control: Control<FieldValues, any>;
}

export interface IntegrationItem {
  connector_id: InputsSchemas | '';
  raw_connector_id?: InputsSchemas | '';
  name: string;
  client_id?: string;
  api_token?: string;
  image_c: string;
  ab_spec: {
    $schema: string;
  };
  versions?: {
    version: number;
    type: string;
  }[];
  active: boolean;
  tags?: string[];
  inputs_schema: {
    $schema: string;
  };
  params?: {
    [key: string]: any;
  };
  short_hash?: string;
  type?: string[];
  supports_multiple_connections?: boolean;
}

export const connectorsDefaultProps = {
  [InputsSchemas.GITLAB]: {
    api_url: 'https://gitlab.com',
    include_patch: true,
    include_requirements: true,
    deployment_type: 'cloud',
  },
  [InputsSchemas.GITHUB]: {
    include_patch: true,
    include_requirements: true,
    deployment_type: 'cloud',
  },
  [InputsSchemas.BITBUCKET]: {
    deployment_type: 'cloud',
    include_patch: true,
    include_requirements: true,
  },
  [InputsSchemas.DOCKERHUB]: {
    deployment_type: 'cloud',
  },
  [InputsSchemas.JIRA]: {},
  [InputsSchemas.OKTA]: {},
  [InputsSchemas.AZURE_AD]: {},
  [InputsSchemas.IDSCIENCE_EMAIL]: {},
  [InputsSchemas.JENKINS]: {
    deployment_type: 'server',
  },
  [InputsSchemas.CIRCLE_CI]: {
    deployment_type: 'cloud',
  },
  [InputsSchemas.AZURE_DEVOPS]: {},
  [InputsSchemas.GOOGLE_DIR]: {},
  [InputsSchemas.SLACK]: {},
  [InputsSchemas.EMAIL]: {},
  [InputsSchemas.SPLUNK]: {
    deployment_type: 'Cloud',
  },
  [InputsSchemas.SPLUNK_INBOUND]: {
    deployment_type: 'cloud',
  },
  [InputsSchemas.TEAMS]: {},
  [InputsSchemas.FLOCK]: {},
  [InputsSchemas.SNYK]: {},
  [InputsSchemas.BLACKDUCK]: {},
  [InputsSchemas.PRISMA]: {},
  [InputsSchemas.INVICTI]: {},
  [InputsSchemas.HARNESS]: {},
  [InputsSchemas.COMPASS]: {},
  [InputsSchemas.ECR]: {
    deployment_type: 'cloud',
  },
  [InputsSchemas.JFROG]: {
    deployment_type: 'cloud',
  },
  [InputsSchemas.SONARQUBE]: {
    deployment_type: 'cloud',
  },
  [InputsSchemas.WEBHOOK]: {},
  [InputsSchemas.FORGE_BITBUCKET]: {},
  [InputsSchemas.BAMBOO]: {
    deployment_type: 'cloud',
  },
};

export interface ConnectorAction {
  connector: InputsSchemas | '';
  description: string;
  connector_spec: {
    channels: string[];
    users: string[];
    message: string;
  };
}
